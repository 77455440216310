.whitelist-button {
    color: white;
    background: linear-gradient(90deg, rgba(234, 156, 104, 1) 0%, rgba(233, 84, 67, 1) 100%);
    height: 6vh;
    width: 15vw;
    border: 0px;
    border-radius: 5px;
}

.whitelist-button:hover {
    box-shadow: 2px 2px 10px 0px rgb(248, 194, 143);
}

@media(max-width:900px) {
    .whitelist-button {
        width: 50vw;
        height: 5vh;
    }

    .whitelist-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 5vh;

    }

    .title-and-button-container {
        display: flex;
        flex-direction: column;
    }
}

.title-and-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-and-button-container a {
    margin-top: 0;
    margin-bottom: 20px;
}

.whitelist-button-container {
    margin-top: 7vh;
    margin-right: 10%;
}

.underlined_heading__disclaimer {
    text-decoration: underline 2px #f57a29;
}


/* @media (min-width:970px) {
    .phil{
        margin:auto; 
        margin-top: 5%;
    }
} */