@use "./config";

*,
::after,
::before {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

strong {
  font-weight: 700;
}

.mw-800 {
  max-width: 800px !important;
}

.z-1 {
  position: relative;
  z-index: 1;
}

body {
  margin: 0;
  padding: 0;
  font-family: config.$bodyfont;
  color: config.$bodyfontcolor;
  font-size: config.$bodyfontsize;
  background-color: config.$bodybg;
  background-image: url(bg-line.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  position: relative;
  min-height: 100vh;
  line-height: 1.5;
  font-weight: 300;
  font-variation-settings: "wdth" 100;
  @include config.flexbox();
  @include config.flex-direction(column);

  &.is-visible {
    overflow: hidden;
  }
}

:focus {
  box-shadow: none !important;
}

ul {
  margin: 0;
  padding: 0;
}

.hightlight-bg {
  //background: url(hightlight-bg.png) no-repeat center;
  background-size: 100% 100%;
  padding: 0 10px;
}

a {
  color: config.$white;
  text-decoration: none;

  &:focus,
  &:hover {
    color: #f57a29;
    text-decoration: none;
  }
}

.btn-primary {
  color: config.$white !important;
  background: linear-gradient(277.72deg,
      #c9009a -32.97%,
      #fc6401 55.57%,
      #f4f4f4 144.11%) padding-box,
    linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%) border-box;
  border: 2px solid transparent;
  min-height: 44px;
  padding: 0 20px;
  font-size: 16px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 400;
  min-width: 130px;
  cursor: pointer;
  @include config.transition(0.3s, ease-in-out);
  @include config.border-radius(6px);
  @include config.inline-flex;
  @include config.align-items(center);
  @include config.justify-content(center);

  strong {
    font-weight: 900;
  }

  img {
    margin-right: 8px;
  }

  &:after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: #fc6401 !important;
    background: linear-gradient(#131623, #131623) padding-box,
      linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%) border-box !important;
    border: 2px solid transparent;
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    background-color: #adbdba;
    border-color: #adbdba;

    &:hover,
    &:focus,
    &:active {
      background: #adbdba !important;
      border-color: #adbdba !important;
    }
  }

  @media (max-width: 767px) {
    min-width: 110px;
    min-height: 40px;
  }
}

.btn-secondery {
  color: config.$white !important;
  background: linear-gradient(#131623, #131623) padding-box,
    linear-gradient(to right, #fa6401, #fa6401) border-box;
  border: 2px solid transparent;
  min-height: 50px;
  padding: 0 10px;
  font-size: 16px;
  min-width: 129px;
  @include config.transition(0.3s, ease-in-out);
  @include config.border-radius(6px);
  @include config.inline-flex;
  @include config.align-items(center);
  @include config.justify-content(center);

  img {
    margin-right: 8px;
  }

  &:after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    background: linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%) padding-box,
      linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%) border-box !important;
    color: config.$white !important;
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    background-color: #adbdba;
    border-color: #adbdba;

    &:hover,
    &:focus,
    &:active {
      background: #adbdba !important;
      border-color: #adbdba !important;
    }
  }

  @media (max-width: 767px) {
    min-width: 110px;
    min-height: 40px;
  }
}

.btn-primary-disabled {
  color: black !important;
  background: rgb(176, 170, 170) padding-box,
    linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%) border-box;
  border: 2px solid transparent;
  min-height: 44px;
  padding: 0 20px;
  font-size: 16px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 400;
  min-width: 130px;
  cursor: pointer;
  @include config.transition(0.3s, ease-in-out);
  @include config.border-radius(6px);
  @include config.inline-flex;
  @include config.align-items(center);
  @include config.justify-content(center);

  strong {
    font-weight: 900;
  }

  img {
    margin-right: 8px;
  }

  &:after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: black !important;
    background: whitesmoke padding-box,
      border-box !important;
    border: 2px solid transparent;
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    background-color: #adbdba;
    border-color: #adbdba;

    &:hover,
    &:focus,
    &:active {
      background: #adbdba !important;
      border-color: #adbdba !important;
    }
  }

  @media (max-width: 767px) {
    min-width: 110px;
    min-height: 40px;
  }

}

.btn-secondary-strip {
  color: config.$white !important;
  background: linear-gradient(#131623, #131623) padding-box,
    linear-gradient(to right, #fa6401, #fa6401) border-box;
  border: 2px solid transparent;
  min-height: 50px;
  padding: 0 10px;
  font-size: 16px;
  min-width: 129px;
  @include config.transition(0.3s, ease-in-out);
  @include config.border-radius(6px);
  @include config.inline-flex;
  @include config.align-items(center);
  @include config.justify-content(center);

  img {
    margin-right: 8px;
  }

  &:after {
    display: none;
  }

  &.disabled,
  &:disabled {
    background-color: #adbdba;
    border-color: #adbdba;

    &:hover,
    &:focus,
    &:active {
      background: #adbdba !important;
      border-color: #adbdba !important;
    }
  }

  @media (max-width: 767px) {
    min-width: 110px;
    min-height: 40px;
  }
}

:focus {
  outline: none !important;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.mw-900 {
  max-width: 900px;

  @media (max-width: 991px) {
    max-width: 500px;
  }
}

section {
  position: relative;
  padding: 50px 0;
  z-index: 1;

  @media (max-width: 991px) {
    padding: 25px 0;
  }
}

.bg-light {
  background-color: #f9f8fa;
}

.inner-line-bg {
  background-image: url(./bg-line.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  @media (max-width: 991px) {
    background-image: none;
  }
}

.circle-shape {
  background-image: url(./bg-circle.svg);
  background-repeat: no-repeat;
  background-position: 110% 12%;
  background-size: 50%;

  @media (max-width: 991px) {
    background-size: 60%;
  }

  @media (max-width: 575px) {
    background-size: 300px;
    background-position: 110% 6%;
  }
}

.main-title {
  margin-bottom: 40px;

  h1,
  h2 {
    font-weight: 900;
    font-size: 48px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: config.$font-Saira;

    span {
      background: rgb(234, 156, 104);
      background: -moz-linear-gradient(90deg,
          rgba(234, 156, 104, 1) 0%,
          rgba(233, 84, 67, 1) 100%);
      background: -webkit-linear-gradient(90deg,
          rgba(234, 156, 104, 1) 0%,
          rgba(233, 84, 67, 1) 100%);
      background: linear-gradient(90deg,
          rgba(234, 156, 104, 1) 0%,
          rgba(233, 84, 67, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }

    .gradient1 {
      background: rgb(234, 156, 104);
      background: -moz-linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
      background: -webkit-linear-gradient(90deg,
          #c9009a 0.01%,
          #fc6401 100.01%);
      background: linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
  }

  p {
    margin: 20px 0 0;
    color: #f4f4f4;
  }

  @media (max-width: 1199px) {

    h1,
    h2 {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;

    h1,
    h2 {
      font-size: 24px;
    }

    p {
      margin-top: 10px;
    }
  }
}

.font-lg {
  @media (min-width: 992px) {
    font-size: 54px !important;
  }
}

.font-md {
  @media (min-width: 992px) {
    font-size: 36px !important;
  }
}

// .container{
//     max-width: 1455px;
// }
.before-shape.before-shape-noHover {
  &:hover {
    &:after {
      right: -5px;
      bottom: -5px;
    }
  }
}

.before-shape.before-shape-none {
  display: none;

  &:hover {
    &:after {
      right: -5px;
      bottom: -5px;
    }
  }
}

.gradient-banner .thumb-img:after {
  content: "";
  background: linear-gradient(90deg, #c9009a11 0.01%, #fc640199 100.01%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.gradient-banner .thumb-img .coming-soon {
  font-family: "Archivo" !important;
  font-weight: 500 !important;
  font-variation-settings: "wdth" 125;
  text-transform: unset !important;
}

.gradient-banner .banner-text {
  position: relative;
  z-index: 9999;
}

.before-shape-none .thumb-img {
  border: unset !important;
  border-radius: 0px !important;
}

.before-shape {
  position: relative;

  &:hover {
    &:after {
      right: 4px;
      bottom: 4px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: -5px;
    bottom: -5px;
    @include config.transition(0.3s, ease-in-out);
    background: linear-gradient(314.45deg,
        #c90099 -70.21%,
        #f67622 58.16%,
        #d8d8d8 143.73%);
    @include config.border-radius(10px);
    z-index: -1;
  }
}

.before-shape.before-shape-home {
  position: relative;

  &:hover {
    &:after {
      right: 4px;
      bottom: 4px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(100% - 5px);
    right: -5px;
    bottom: -0px;

    @media (max-width: 767px) {
      bottom: 2px;
      height: calc(100% - 10px);
    }

    @include config.transition(0.3s, ease-in-out);
    background: linear-gradient(314.45deg,
      #c90099 -70.21%,
      #f67622 58.16%,
      #d8d8d8 143.73%);
    @include config.border-radius(10px);
    z-index: -1;
  }
}

.border-bottom-gradient {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%);
  }
}

.main-header {
  position: fixed;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2000;
  @include config.transition(0.3s, ease-in-out);

  &.fixed-header {
    @include config.box-shadow(0px 6px 20px rgba(0, 0, 0, 0.08));
    background-color: rgba(0, 0, 0, 0.6);

    .navbar {
      padding: 15px 0;
    }
  }

  .navbar {
    padding: 20px 0 20px;
    @include config.transition(0.3s, ease-in-out);

    >.container {
      display: block;
    }
  }

  .navbar-nav {
    @include config.justify-content(flex-end);
  }

  .navbar-nav .nav-item {
    font-family: "Archivo";
    font-weight: 500;
    font-variation-settings: "wdth" 125;
    font-size: 14px;
    margin: 0 15px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav .nav-item {
      font-size: 12px;
    }
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    @include config.transition(0.4s, ease-in-out);
    display: inline-block;
    vertical-align: middle;

    img {
      @include config.transition(0.4s, ease-in-out);
      vertical-align: text-bottom;
    }
  }

  .menu-list {
    margin-left: auto;
    margin-right: 40px;

    ul {
      @include config.align-items(center);
    }

    li {
      margin: 0 10px;
      padding: 8px 0;
      position: relative;

      &.has-sub-menu {
        .dropdown-menu-list {
          li {
            a {
              font-weight: 300;
              white-space: nowrap;
            }
          }
        }
      }

      &.has-sub-menu-profile {
        .dropdown-menu-list {
          position: absolute;
          min-width: 170px;
          top: 100%;
          right: 0;
          display: block;
          background: config.$darkBlack;
          border: 1px solid #f57a29;
          visibility: visible;
          @include config.transform(translateY(20px));
          margin: 0px;
          list-style: none;
          padding: 10px 0;

          li {
            padding: 10px 18px;
            margin: 0;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: config.$white;
        position: relative;
        z-index: 1;
        font-weight: 4500;
        @include config.flexbox();
        @include config.align-items(center);
        @include config.transition(0.3s, ease-in-out);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .menu-list-show {
    margin-left: auto;
    margin-right: 40px;

    ul {
      @include config.align-items(center);
    }

    li {
      margin: 0 10px;
      padding: 8px 0;
      position: relative;

      &.has-sub-menu {
        .dropdown-menu-list {
          li {
            a {
              font-weight: 300;
              white-space: nowrap;
            }
          }
        }
      }

      &.has-sub-menu-profile {
        .dropdown-menu-list {
          position: absolute;
          min-width: 170px;
          top: 100%;
          right: 0;
          display: block;
          background: config.$darkBlack;
          border: 1px solid #f57a29;
          visibility: visible;
          @include config.transform(translateY(20px));
          margin: 0px;
          list-style: none;
          padding: 10px 0;

          li {
            padding: 10px 18px;
            margin: 0;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: config.$white;
        position: relative;
        z-index: 1;
        font-weight: 4500;
        @include config.flexbox();
        @include config.align-items(center);
        @include config.transition(0.3s, ease-in-out);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .menu-list-profile {
    margin-left: auto;
    margin-right: 40px;

    ul {
      @include config.align-items(center);
    }

    li {
      margin: 0 10px;
      padding: 8px 0;
      position: relative;

      &.has-sub-menu-profile {
        .dropdown-menu-list {
          position: absolute;
          min-width: 170px;
          top: 30px;
          right: 0;
          transition-delay: 2s;
          background: config.$darkBlack;
          border: 1px solid #f57a29;
          visibility: visible;
          @include config.transform(translateY(20px));
          margin: 0px;
          list-style: none;
          padding: 10px 0;

          li {
            padding: 10px 18px;
            margin: 0;
          }
        }

        .dropdown-menu-list-collapse {
          display: none;
          position: absolute;
          transition-delay: 2s;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: config.$white;
        position: relative;
        z-index: 1;
        font-weight: 4500;
        @include config.flexbox();
        @include config.align-items(center);
        @include config.transition(0.3s, ease-in-out);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .menu-list {
    margin-left: auto;
    margin-right: 40px;

    ul {
      @include config.align-items(center);
    }

    li {
      margin: 0 10px;
      padding: 8px 0;
      position: relative;

      &.has-sub-menu {
        .dropdown-menu-list {
          li {
            a {
              font-weight: 300;
              white-space: nowrap;
            }
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: config.$white;
        position: relative;
        z-index: 1;
        font-weight: 4500;
        @include config.flexbox();
        @include config.align-items(center);
        @include config.transition(0.3s, ease-in-out);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .menu-login-btn {
    @include config.flexbox();
    @include config.align-items(center);
    @include config.justify-content(space-between);
  }

  // @media (min-width: 992px) {
  //   .menu-list {
  //     li {
  //       a {
  //         &:hover {
  //           color: #f57a29;
  //           &:after {
  //             width: 100%;
  //             right: auto;
  //             left: 0;
  //           }
  //         }
  //         &:after {
  //           content: "";
  //           position: absolute;
  //           z-index: -1;
  //           right: 0;
  //           bottom: 0;
  //           background: #f57a29;
  //           width: 0;
  //           height: 1px;
  //           @include config.transition(0.3s, ease-in-out);
  //         }
  //       }
  //     }
  //   }
  // }
  @media (min-width: 992px) {
    .menu-list {
      li {
        &.has-sub-menu {
          >a {
            position: relative;
            padding-right: 24px;

            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              display: inline-block;
              height: 16px;
              width: 16px;
              background: url(arrow-down.svg) center no-repeat;
              background-size: contain;
              background-position: center;
              @include config.transition(0.3s, ease-in-out);
            }
          }

          &:hover {
            >a {
              &:before {
                @include config.transform(rotate(-180deg));
              }
            }

            .dropdown-menu-list {
              @include config.opacity(1);
              visibility: visible;
              @include config.transform(translateY(0));
              @include config.transition(0.3s, ease-in-out);
            }
          }

          .dropdown-menu-list {
            position: absolute;
            min-width: 170px;
            top: 100%;
            right: 0;
            @include config.opacity(0);
            display: block !important;
            background: config.$darkBlack;
            visibility: hidden;
            border: 1px solid #f57a29;
            @include config.transform(translateY(20px));
            margin: 0px;
            list-style: none;
            padding: 10px 0;

            li {
              padding: 10px 18px;
              margin: 0;

              a {
                font-size: 16px;

                &:after {
                  display: none;
                }
              }
            }
          }
        }

        &.has-sub-menu-profile {
          >a {
            position: relative;
            padding-right: 24px;

            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              display: inline-block;
              height: 16px;
              width: 16px;
              background-size: contain;
              background-position: center;
              @include config.transition(0.3s, ease-in-out);
            }
          }

          &:hover {
            >a {
              &:before {
                @include config.transform(rotate(-180deg));
              }

              &:after {
                background: #000;
              }
            }

            .dropdown-menu-list {
              @include config.opacity(1);
              visibility: visible;
              @include config.transform(translateY(0));
              @include config.transition(0.3s, ease-in-out);
            }
          }

          .dropdown-menu-list {
            position: absolute;
            min-width: 170px;
            top: 100%;
            right: 0;
            @include config.opacity(0);
            display: block !important;
            background: config.$darkBlack;
            visibility: hidden;
            border: 1px solid #f57a29;
            @include config.transform(translateY(20px));
            margin: 0px;
            list-style: none;
            padding: 10px 0;

            li {
              padding: 10px 18px;
              margin: 0;

              a {
                font-size: 16px;

                &:after {
                  display: none;
                }
              }
            }
          }
        }

        a {
          &:hover {
            color: #f57a29;

            &:after {
              width: 100%;
              right: auto;
              left: 0;
            }
          }

          &:after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 0;
            background: #f57a29;
            width: 0;
            height: 2px;
            @include config.transition(0.3s, ease-in-out);
          }
        }
      }
    }
  }

  @media (max-width: 1499px) {
    .menu-list {
      li {
        margin: 0 10px;

        a {
          //font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .menu-list {
      width: 300px !important;
    }
  }

  @media (max-width: 348px) {
    .menu-list {
      width: 100% !important;
      top: 100px !important;
    }
  }

  @media (max-width: 991px) {
    .menu-login-btn {
      @include config.justify-content(flex-end);

      .btn-primary {
        min-height: 36px;
        padding: 0 2px;
        font-size: 12px;
        min-width: 100px;
      }
    }

    &.is-visible {
      background-color: #131623;

      .menu-list {
        @include config.transform(translateX(0px));
      }
    }

    .navbar {
      min-height: 66px;
      padding: 10px 0;

      >.container {
        max-width: 100%;
      }

      &:after {
        display: none;
      }
    }

    .navbar-brand {
      img {
        max-width: 120px;
      }
    }

    .sidebar-icon {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 15px;

      &.is-visible {
        .line1 {
          @include config.transform(rotate(-45deg) translate(-4px, 7px));
        }

        .line2 {
          @include config.opacity(0);
          visibility: hidden;
        }

        .line3 {
          @include config.transform(rotate(45deg) translate(-4px, -7px));
        }
      }

      span {
        width: 24px;
        height: 2px;
        margin: 6px 0;
        background-color: config.$white;
        @include config.border-radius(4px);
        display: block;
        @include config.transition(0.4s, ease-in-out);
      }
    }

    .menu-list {
      >ul {
        display: block;
      }

      min-height: auto;
      overflow-y: auto;
      width: 300px;
      display: block;
      padding: 15px;
      background-color: #131623;
      @include config.transition(0.4s, ease-in-out);
      @include config.transform(translateX(100%));
      position: fixed;
      top: 80px;
      right: 0;
      //bottom: 0;
      margin-right: 0px;
      border: 1px solid white;

      li {
        margin: 0;
        padding: 10px 0;
      }

      .has-sub-menu {
        position: relative;

        &>a {
          position: relative;

          &+em {
            position: absolute;
            right: 0;
            top: 9px;
            width: 25px;
            height: 25px;
            background: url(arrow-down.svg) center no-repeat;
            cursor: pointer;
            z-index: 1;
            @include config.transition(0.3s, ease-in-out);

            &[aria-expanded="true"] {
              @include config.transform(rotate(180deg));
            }
          }
        }

        &:hover,
        &:focus {
          .dropdown-menu-list {
            @include config.opacity(1);
            visibility: visible;
            @include config.transform(translateY(0));
            @include config.transition(0.3s, ease-in-out);
          }
        }

        .dropdown-menu-list {
          padding-left: 15px;

          li {
            &:first-child {
              padding-top: 20px;
            }

            a {
              font-size: 14px;
            }
          }
        }
      }

      .has-sub-menu-profile {
        position: relative;

        .dropdown-menu-list {
          padding-left: 15px;
        }

        .dropdown-menu-list-collapse {
          display: none;
          position: absolute;
        }
      }
    }

    .menu-list-profile {
      ul {
        @include config.align-items(center);
      }

      li {
        margin: 0 10px;
        padding: 8px 0;
        position: relative;

        &.has-sub-menu-profile {
          position: relative;

          .dropdown-menu-list {
            padding-left: 15px;

            .show {
              display: block !important;
            }

            .collapse {
              display: none;
            }
          }

          .dropdown-menu-list-collapse {
            display: none;
            position: absolute;
          }
        }
      }
    }
  }

  .header-first-block {
    .logo-signup-btn {
      .sign-up-link {
        display: none !important;
      }
    }
  }
}

.bg-overly {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -110%;
  width: 100%;
  @include config.transition(0.4s, ease-in-out);
  background: #131623;
  @include config.opacity(0.5);
  z-index: 9;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0.1, 0.68, 0.53) 0.4s;
  transition: all 0.6s cubic-bezier(0.6, 0.1, 0.68, 0.53) 0.4s;

  &.is-visible {
    left: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.22, 0.61, 0.24, 1) 0s;
    transition: all 0.6s cubic-bezier(0.22, 0.61, 0.24, 1) 0s;
  }
}

.main-footer {
  .footer-link-section {
    padding: 50px 0;

    h2 {
      font-size: 18px;
      color: config.$white;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-family: "Archivo";
      font-weight: 500;
      font-variation-settings: "wdth" 125;
    }

    .footer-links {
      li {
        padding: 5px 0;
        font-family: "Archivo";
        font-weight: 500;
        font-variation-settings: "wdth" 125;

        a {
          background: rgb(234, 156, 104);
          background: -moz-linear-gradient(314.45deg,
              #c90099 -70.21%,
              #f67622 58.16%,
              #d8d8d8 143.73%);
          background: -webkit-linear-gradient(314.45deg,
              #c90099 -70.21%,
              #f67622 58.16%,
              #d8d8d8 143.73%);
          background: linear-gradient(314.45deg,
              #c90099 -70.21%,
              #f67622 58.16%,
              #d8d8d8 143.73%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          z-index: 1;
          font-weight: 300;

          &:hover {
            &:after {
              width: 100%;
              right: auto;
              left: 0;
            }
          }

          &:after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: -2px;
            background: #f57a29;
            width: 0;
            height: 1px;
            @include config.transition(0.3s, ease-in-out);
          }
        }
      }
    }
  }

  .social-icon {
    padding-top: 5px;
    font-family: "Archivo";
    font-variation-settings: "wdth" 125;
    @include config.flexbox();
    flex-wrap: wrap;

    .social-item {
      @include config.inline-flex;
      align-items: flex-start;
      margin-bottom: 18px;
      width: 50%;

      &:hover {
        color: config.$white;
      }

      span {
        background: rgb(234, 156, 104);
        background: -moz-linear-gradient(90deg,
            rgba(234, 156, 104, 1) 0%,
            rgba(233, 84, 67, 1) 100%);
        background: -webkit-linear-gradient(90deg,
            rgba(234, 156, 104, 1) 0%,
            rgba(233, 84, 67, 1) 100%);
        background: linear-gradient(90deg,
            rgba(234, 156, 104, 1) 0%,
            rgba(233, 84, 67, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        z-index: 1;

        &:hover {
          &:after {
            width: 100%;
            right: auto;
            left: 0;
          }
        }

        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          right: 0;
          bottom: -2px;
          background: #f57a29;
          width: 0;
          height: 1px;
          @include config.transition(0.3s, ease-in-out);
        }
      }

      .inner-text {
        margin-left: 15px;
      }
    }
  }

  @media (max-width: 991px) {
    .footer-link-section {
      h2 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .footer-links {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .footer-logo {
      margin-bottom: 30px;
    }

    .footer-link-section {
      padding: 40px 0 20px;
    }

    .social-icon {
      .social-item {
        width: 100%;

        .inner-text {
          font-size: 14px;
        }
      }
    }
  }
}

.mw-760 {
  max-width: 760px;
}

.section-block {
  position: relative;
  padding: 50px 0;
  z-index: 1;

  @media (max-width: 991px) {
    padding: 25px 0;
  }
}

.full-section-block {
  padding: 0px;
}

.main-banner {
  .main-title {
    margin-bottom: 30px;

    h1 {
      font-family: config.$font-Abril-Fatface;
      font-weight: 400;
      text-transform: inherit;
    }
  }

  .btn-action {
    @include config.flexbox();
    @include config.align-items(center);

    a {
      min-width: auto;
      max-width: 194px;
      width: 100%;

      ~a {
        margin-left: 15px;
      }
    }

    .btn-secondery {
      background: transparent;
      border: 2px solid #fa6401;

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active:focus {
        background: linear-gradient(277.72deg,
            #c9009a -32.97%,
            #fc6401 55.57%,
            #f4f4f4 144.11%) padding-box,
          linear-gradient(277.72deg,
            #c9009a -32.97%,
            #fc6401 55.57%,
            #f4f4f4 144.11%) border-box !important;
        border: 2px solid transparent;
      }
    }
  }

  .banner-slider {
    /*@include config.border-radius(10px);
    border: 1px solid #fc6401;
    margin: 0;
    overflow: hidden;*/

    &.slider-slick:not(.slick-initialized)>.slick-slide:not(.slick-active) {
      display: none;
    }

    .banner-item {
      position: relative;
      z-index: 1;
      overflow: hidden;

      .banner-content {
        padding: 100px;
        position: relative;
      }

      .banner-video {
        aspect-ratio: 3 / 1;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .thumb-img {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        border-left: 1px solid #fc6401;
        border-right: 1px solid #fc6401;
        border-top: 1px solid #fc6401;
        //height: 450px;
        aspect-ratio: 3 / 1;
        background-size: cover !important;
        background-position: center center !important;

        img {
          width: 100%;
          max-height: 413px;
        }

        &.for-video {
          height: 550px !important;
          border: 0px solid transparent;
        }
      }

      .thumb-img-individual-nft {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        border-left: 1px solid #fc6401;
        border-right: 1px solid #fc6401;
        border-top: 1px solid #fc6401;
        height: 450px;
        background-size: cover !important;
        background-position: center center !important;

        img {
          width: 100%;
          max-height: 413px;
        }

        &.for-video {
          height: 550px !important;
          border: 0px solid transparent;
        }
      }


      @media (max-width: 1400px) {
        .thumb-img {
          &.for-video {
            height: 490px !important;
            border: 0px solid transparent;
          }
        }

        .thumb-img-individual-nft {
          &.for-video {
            height: 490px !important;
            border: 0px solid transparent;
          }

          &.for-image {
            height: 490px !important;
            border: 0px solid transparent;
          }
        }
      }

      @media (max-width: 1200px) {
        .thumb-img {
          &.for-video {
            height: 415px !important;
            border: 0px solid transparent;
          }
        }

        .thumb-img-individual-nft {
          &.for-video {
            height: 415px !important;
            border: 0px solid transparent;
          }

          &.for-image {
            height: 415px !important;
            border: 0px solid transparent;
          }
        }
      }

      @media (max-width: 991px) {
        .thumb-img {
          //height: 300px !important;
          padding: 20px;

          &.for-video {
            height: 460px !important;
            border: 0px solid transparent;
          }
        }

        .thumb-img-individual-nft {
          height: 300px !important;
          padding: 20px;

          &.for-video {
            height: 460px !important;
            border: 0px solid transparent;
          }

          &.for-image {
            height: 460px !important;
          }
        }
      }

      @media (max-width: 767px) {
        .thumb-img {
          //height: 250px !important;
          padding: 20px;

          &.for-video {
            height: 360px !important;
            border: 0px solid transparent;
          }
        }

        .thumb-img-individual-nft {
          height: 250px !important;
          padding: 20px;

          &.for-video {
            height: 360px !important;
            border: 0px solid transparent;
          }

          &.for-image {
            height: 360px !important;
            border: 0px solid transparent;
          }
        }
      }

      @media (max-width: 540px) {
        .thumb-img {
          //height: 250px !important;
          padding: 20px;

          &.for-video {
            height: 68vw !important;
            border: 0px solid transparent;
          }
        }

        .thumb-img-individual-nft {
          height: 250px !important;
          padding: 20px;

          &.for-video {
            height: 68vw !important;
            border: 0px solid transparent;
          }

          &.for-image {
            height: 68vw !important;
            border: 0px solid transparent;
          }
        }
      }

      .banner-text {
        position: absolute;
        bottom: 40px;
        left: 21px;

        span.coming-soon {
          font-size: 30px;
          text-transform: uppercase;
          font-weight: 900;
          font-family: config.$font-Saira;
          line-height: 100%;
        }

        @media (max-width: 991px) {
          span.coming-soon {
            font-size: 20px;
          }

          .thumb-img {
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              max-height: 222px;
            }
          }

          .thumb-img-individual-nft {
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              max-height: 222px;
            }
          }
        }

        @media (max-width: 767px) {
          right: 0;
          left: 0;
          text-align: center;
          padding: 5px;

          span.coming-soon {
            font-size: 18px;
          }

          .thumb-img {
            position: relative;
            overflow: hidden;
            height: 250px !important;

            img {
              width: 100%;
              max-height: 150px;
            }
          }

          .thumb-img-individual-nft {
            position: relative;
            overflow: hidden;
            height: 250px !important;

            img {
              width: 100%;
              max-height: 150px;
            }
          }
        }
      }
    }

    .slick-prev {
      width: 30px;
      height: 30px;
      background: url(arrow-left.svg) no-repeat center;
      background-size: contain;
      top: auto;
      bottom: 50px;
      left: auto;
      right: 90px;
      transform: none;
      z-index: 1;

      &::before {
        display: none;
      }
    }

    .slick-next {
      width: 30px;
      height: 30px;
      background: url(arrow-right.svg) no-repeat center;
      background-size: contain;
      top: auto;
      bottom: 50px;
      right: 60px;
      transform: none;

      &::before {
        display: none;
      }
    }

    .slick-dots {
      bottom: 60px;
      right: 130px;
      width: auto;
    }
  }

  @media (max-width: 1199px) {
    .banner-slider {
      .banner-item {
        .banner-content {
          padding: 60px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .banner-slider {
      .banner-item {
        .banner-content {
          padding: 40px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .banner-slider {
      .slick-dots {
        width: 100%;
        bottom: 25px;
        right: 0;
        @include config.justify-content(center);
      }

      .banner-item {
        .banner-content {
          padding: 25px 25px 60px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .banner-slider {
      .banner-item {
        .thumb-img {
          background-size: cover !important;
          //height: 200px !important;

          .full-img {
            min-height: 150px;
            object-fit: cover;
          }
        }

        .thumb-img-individual-nft {
          background-size: cover !important;
          height: 200px !important;

          .full-img {
            min-height: 150px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.single-banner {
  .banner-slider {
    .slick-arrow {
      filter: invert(1);
    }

    .slick-dots {
      li {
        button {
          background: #131623;
        }
      }
    }
  }

  .banner-content-block {
    @include config.border-radius(10px);
    border: 1px solid transparent;
    background: linear-gradient(#131623, #131623) padding-box,
      linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%) border-box;
    padding: 30px;
    height: 100%;

    h2 {
      font-weight: 900;
      font-size: 24px;
      text-transform: uppercase;
      color: #ffffff;
      font-family: config.$font-Saira;
      margin-bottom: 20px;
    }

    .setting-user {
      @include config.flexbox();
      @include config.align-items(center);
      @include config.justify-content(space-between);
      margin-bottom: 50px;
      padding-bottom: 28px;

      .user-names {
        span {
          font-weight: normal;
          font-size: 16px;
          color: #ffffff;
          @include config.flexbox();
          @include config.align-items(center);
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            margin-right: 10px;
            flex: none;
          }
        }
      }

      .share-btn {
        background: linear-gradient(#131623, #131623) padding-box,
          linear-gradient(to right, #fa6401, #fa6401) border-box;
        border: 2px solid transparent;
        width: 60px;
        height: 60px;
        @include config.border-radius(6px);
        @include config.flexbox();
        @include config.align-items(center);
        @include config.justify-content(center);
        @include config.transition(0.3s, ease-in-out);
        cursor: pointer;

        &:hover {
          background: linear-gradient(277.72deg,
              #c9009a -32.97%,
              #fc6401 55.57%,
              #f4f4f4 144.11%) padding-box,
            linear-gradient(277.72deg,
              #c9009a -32.97%,
              #fc6401 55.57%,
              #f4f4f4 144.11%) border-box;
        }
      }
    }

    .top-bar-block {
      background: linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
      padding: 30px;
      margin: -30px -30px 50px;
    }

    .price-inner-block {
      margin-top: 50px;

      h3 {
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: config.$font-Saira;

        span {
          background: rgb(234, 156, 104);
          background: -moz-linear-gradient(90deg,
              rgba(234, 156, 104, 1) 0%,
              rgba(233, 84, 67, 1) 100%);
          background: -webkit-linear-gradient(90deg,
              rgba(234, 156, 104, 1) 0%,
              rgba(233, 84, 67, 1) 100%);
          background: linear-gradient(90deg,
              rgba(234, 156, 104, 1) 0%,
              rgba(233, 84, 67, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }

      .dec-text {
        p {
          margin-bottom: 0;
        }
      }

      .history-block {
        margin-top: 30px;
        max-width: 335px;
        border-radius: 10px;
        border: 1px solid #eb3636;
        padding: 20px;
        overflow-y: auto;
        max-height: 280px;

        &::-webkit-scrollbar-thumb {
          background-color: config.$white;
          @include config.border-radius(100px);
        }

        &::-webkit-scrollbar {
          width: 3px;
          background-color: transparent;
        }

        h4 {
          font-weight: normal;
          font-size: 28px;
          color: #f4f4f4;
          font-family: config.$font-Aldrich;
          margin-bottom: 20px;
        }

        .dec-text {
          margin-bottom: 50px;

          p {
            font-size: 18px;
            @include config.flexbox();
            @include config.align-items(center);
          }

          span {
            width: 50%;
            display: inline-block;
          }
        }

        .price-text {
          @include config.flexbox();
          @include config.align-items(center);

          p {
            background: rgb(234, 156, 104);
            background: -moz-linear-gradient(90deg,
                rgba(234, 156, 104, 1) 0%,
                rgba(233, 84, 67, 1) 100%);
            background: -webkit-linear-gradient(90deg,
                rgba(234, 156, 104, 1) 0%,
                rgba(233, 84, 67, 1) 100%);
            background: linear-gradient(90deg,
                rgba(234, 156, 104, 1) 0%,
                rgba(233, 84, 67, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
            font-size: 24px;
            margin-bottom: 0;
          }

          span {
            color: #f4f4f4;
            font-size: 14px;
            margin-left: 25px;
          }
        }
      }

      @media (max-width: 767px) {
        .history-block {
          h4 {
            font-size: 18px;
          }

          .price-text,
          .dec-text {
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .banner-content-block {
      padding: 20px;

      h2 {
        font-size: 20px;
      }

      .setting-user {
        margin-bottom: 30px;
        padding-bottom: 20px;
      }
    }
  }

  @media (max-width: 991px) {
    .banner-content-block {
      margin-top: 35px;
      height: auto;

      h2 {
        font-size: 18px;
      }

      .setting-user {
        .user-names {
          span {
            font-size: 14px;
          }
        }

        .share-btn {
          width: 40px;
          height: 40px;

          img {
            width: 20px;
          }
        }
      }
    }
  }
}

.slick-dots {
  @include config.flexbox();

  li {
    width: auto;
    height: auto;

    &.slick-active {
      button {
        opacity: 1;
        border-radius: 10px;
        width: 15px;
      }
    }

    button {
      width: 6px;
      height: 6px;
      padding: 0;
      background: #f4f4f4;
      opacity: 0.3;
      border-radius: 10px;

      &::before {
        display: none;
      }
    }
  }
}

.main-content-wrapper {
  flex: 1 1 auto;
  padding-top: 85px;

  @media (max-width: 991px) {
    padding-top: 66px;
  }
}

.shape-image {
  //background-image: url(./bg-shape.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 100% 65%;

  @media (max-width: 1500px) {
    background-size: 100% 60%;
  }

  @media (max-width: 1150px) {
    background-size: 100% 56%;
  }

  @media (max-width: 991px) {
    background-size: 100% 43%;
  }

  @media (max-width: 767px) {
    background-size: contain;
  }
}

.circle-image {
  background: url("./bg-circle.svg") no-repeat right 90px;
  background-size: 45%;
}

@media (max-width: 991px) {
  .circle-image {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("./bg-circle.svg") no-repeat right 95px;
    background-size: 65%;
  }
}

@media (max-width: 991px) {
  .br-none br {
    display: none;
  }
}

// bottom-content
.bottom-content {
  padding-top: 25px;

  h2 {
    font-size: 54px;
    margin-bottom: 15px;
    line-height: 100%;

    span {
      background: rgb(234, 156, 104);
      background: -moz-linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
      background: -webkit-linear-gradient(90deg,
          #c9009a 0.01%,
          #fc6401 100.01%);
      background: linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
  }

  p {
    font-size: 20px;
  }

  .btn-primary {
    max-width: 275px;
    width: 100%;
    min-width: auto;
    margin-top: 50px;
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 34px;
      line-height: 34px;
    }

    p {
      font-size: 16px;
    }

    .btn-primary {
      margin-top: 25px;
    }
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 28px;
      line-height: 28px;
    }

    .btn-primary {
      margin-top: 25px;
    }
  }
}

.hover-line {
  position: relative;
  z-index: 1;
  display: inline-flex !important;
  @include config.align-items(center);

  &:hover {
    &:before {
      width: 100%;
      right: auto;
      left: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    background: #f57a29;
    width: 0;
    height: 2px;
    @include config.transition(0.3s, ease-in-out);
  }
}

// category-items
.category-items {
  margin: 0 -15px;

  .category-item {
    position: relative;
    z-index: 1;
    margin: 10px;
    position: relative;
    z-index: 1;
    flex: 0 0 calc(25% - 20px);

    @media (max-width: 1200px) {
      flex: 0 0 calc(33% - 20px);
    }

    @media (max-width: 991px) {
      flex: 0 0 calc(50% - 20px);
    }

    @media (max-width: 991px) {
      flex: 0 0 calc(50% - 20px);
    }

    @media (max-width: 540px) {
      flex: 0 0 calc(100% - 20px);
    }

    &:hover {
      &:after {
        right: 7px;
        bottom: 7px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      right: -5px;
      bottom: -5px;
      @include config.transition(0.3s, ease-in-out);
      background: linear-gradient(130.5deg,
          #d8d8d8 -31.81%,
          #dec2b0 -5.61%,
          #f67622 63.41%,
          #c90099 100.73%);
      @include config.border-radius(10px);
      z-index: -1;
    }
  }

  .card {
    border: 1px solid coral;
    //background-color: #040404;
    overflow: hidden;
    @include config.border-radius(10px);

    .card-body {
      padding: 8px;
      background: #131623;

      .title-sub-title {
        @include config.flexbox();
        @include config.justify-content(space-between);

        .card-title {
          font-size: 12px;
          color: #f4f4f4;
          font-family: config.$font-Aldrich;
          margin: 0 8px 0 0;
        }

        span {
          font-family: config.$font-Actor;
          font-size: 14px;
          color: #f4f4f4;
          padding-left: 5px;
          text-align: right;
          flex: none;
          position: relative;
          bottom: 5px;
        }
      }

      .price-block {
        @include config.flexbox();
        @include config.align-items(center);
        @include config.justify-content(space-between);
        margin-top: 2px;

        span {
          font-size: 14px;
          display: block;
          color: #f57a29;
        }

        p {
          font-size: 14px;
          color: #f4f4f4;
          margin: 0;
          text-align: right;
        }
      }
    }
  }

  div.slick-list {
    padding-right: 12px;
  }

  @media (max-width: 767px) {
    .card {
      .card-body {
        padding: 15px;

        .title-sub-title {
          .card-title {
            font-size: 15px;
          }
        }

        .price-block {
          margin-top: 10px;
        }
      }
    }
  }
}

// slider-arrows
.slider-arrows-wrapper {
  @include config.inline-flex;
  margin: 0 -12px;

  .slider-arrow {
    background: linear-gradient(#131623, #131623) padding-box,
      linear-gradient(to right, #fa6401, #fa6401) border-box;
    border: 2px solid transparent;
    width: 60px;
    height: 60px;
    margin: 0 12px;
    @include config.border-radius(6px);
    @include config.transition(0.3s, ease-in-out);

    &:hover {
      background: linear-gradient(277.72deg,
          #c9009a -32.97%,
          #fc6401 55.57%,
          #f4f4f4 144.11%) padding-box,
        linear-gradient(277.72deg,
          #c9009a -32.97%,
          #fc6401 55.57%,
          #f4f4f4 144.11%) border-box;
    }
  }

  @media (max-width: 991px) {
    margin: 0 -5px;

    .slider-arrow {
      width: 40px;
      height: 40px;
      margin: 0 5px;

      svg {
        width: 8px;
      }
    }
  }
}

// title-card-section
.top-right-arrow {
  @media (min-width: 768px) {
    .main-title {
      @include config.flexbox();
      @include config.align-items(center);
      @include config.justify-content(space-between);
    }
  }

  @media (max-width: 767px) {
    .main-title {
      text-align: center;

      h2 {
        margin-bottom: 20px;
      }
    }
  }
}

// .bottom-left-arrow
.bottom-left-arrow {
  .main-title {
    margin-bottom: 0;

    h2 {
      line-height: 1.5;
      margin-bottom: 50px;
    }
  }

  @media (max-width: 991px) {
    .main-title {
      h2 {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .main-title {
      text-align: center;
      margin-bottom: 15px;
    }
  }
}

// arrow-left-right
.arrow-left-right {
  .slick-dots {
    @include config.justify-content(center);
  }

  // div.slick-list {
  //     padding-right: 5px;
  // }
  .slick-arrow {
    width: 24px;
    height: 42px;

    &::before {
      display: none;
    }

    &.slick-prev {
      background: url(./angle-left.svg) no-repeat center;
      left: 30px;
      z-index: 1;
      background-size: contain;
    }

    &.slick-next {
      background: url(./angle-right.svg) no-repeat center;
      right: 30px;
      background-size: contain;
    }

    @media (max-width: 991px) {
      width: 12px;
      height: 24px;
    }
  }

  @media (min-width: 992px) {
    div.slick-list {
      padding-right: 0;
    }
  }
}

// featured-section
.featured-section {
  // .main-title{
  //     h2{
  //         font-weight: 400;
  //         letter-spacing: 6px;
  //     }
  // }

  .inner-content {
    .card-image {
      img {
        @include config.border-radius(10px);
      }
    }

    .card-content {
      h3 {
        font-weight: normal;
        color: #f4f4f4;
        margin-bottom: 15px;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 900;
        font-family: config.$font-Saira;
      }

      p {
        font-size: 20px;
        text-transform: uppercase;
        font-family: config.$font-Archivo;
      }

      .timer-content {
        margin-top: 10px;
        @include config.inline-flex;
        @include config.align-items(center);
        font-weight: bold;
        font-size: 32px;
        line-height: 100%;
        font-family: config.$font-Saira;
        background: rgb(234, 156, 104);
        background: -moz-linear-gradient(314.45deg,
            #c90099 -70.21%,
            #f67622 58.16%,
            #d8d8d8 143.73%);
        background: -webkit-linear-gradient(314.45deg,
            #c90099 -70.21%,
            #f67622 58.16%,
            #d8d8d8 143.73%);
        background: linear-gradient(314.45deg,
            #c90099 -70.21%,
            #f67622 58.16%,
            #d8d8d8 143.73%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .timer-block {
          @include config.flexbox();
          @include config.align-items(center);
          margin-left: 15px;

          p {
            margin: -5px 10px 0 10px;
            font-size: 47px;
            line-height: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .inner-content {
      .card-content {
        h3 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }

        .timer-content {
          font-size: 24px;
          margin-top: 10px;

          .timer-block {
            margin: -4px 10px 0 10px;

            p {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .inner-content {
      .card-content {
        margin-top: 30px;

        p {
          font-size: 14px;
        }

        h3 {
          font-size: 22px;
        }

        .timer-content {
          font-size: 20px;
          margin-top: 10px;

          .timer-block {
            margin: -4px 10px 0 10px;

            p {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .inner-content {
      .card-content {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}

.show-more-btn {
  font-weight: normal;
  font-size: 18px;
  color: #f4f4f4;
  text-align: center;
  margin-top: 50px;

  a {
    @include config.transition(0.3s, ease-in-out);
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      svg {
        @include config.transform(translateY(5px));
      }
    }
  }

  svg {
    display: block;
    margin: auto;
    @include config.transition(0.3s, ease-in-out);
    width: 30px;
  }

  @media (max-width: 991px) {
    font-size: 16px;
    margin-top: 20px;

    svg {
      width: 20px;
    }
  }
}

// market-section
.market-section {
  margin-top: -50px;
  padding-top: 100px;

  .main-title {
    h2 {
      // font-weight: 400;
      // letter-spacing: 6px;
    }

    p {
      margin: 0 0 20px;
      font-family: config.$font-Archivo;
      font-size: 20px;
    }

    .btn-primary {
      min-width: auto;
      max-width: 350px;
      width: 100%;
      font-family: config.$font-Saira;
    }
  }

  .slick-arrow {
    &.slick-prev {
      left: 25px;
    }

    &.slick-next {
      right: 25px;
    }

    @media (min-width: 1440px) {

      // initial was 20 20px for both
      &.slick-prev {
        left: 35px;
      }

      &.slick-next {
        right: 35px;
      }
    }
  }

  .category-items {
    div.slick-list {
      padding-right: 0;
    }

    // initial was 0 -15px
    @media (min-width: 1440px) {
      .slick-list {
        margin: 0 15px;
      }

      .category-item {
        margin: 15px 15px;
      }
    }
  }

  @media (max-width: 991px) {
    .main-title {
      h2 {
        letter-spacing: inherit;
      }

      p {
        font-size: 16px;
        margin-top: 6px;
      }
    }
  }
}

// individual-block
.individual-block {
  padding-top: 0;

  .banner-content-block {
    @include config.border-radius(10px);
    border: 1px solid transparent;
    background: linear-gradient(#131623, #131623) padding-box,
      linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%) border-box;
    height: calc(100vh - 160px);
    @include config.flexbox();
    @include config.flex-direction(column);

    h2 {
      font-weight: 900;
      font-size: 24px;
      text-transform: uppercase;
      color: #ffffff;
      font-family: config.$font-Saira;
      margin-bottom: 20px;
    }

    p {
      letter-spacing: 1px;
    }

    .scroll-block {
      //padding: 22px;
      overflow-y: auto;
      flex: 1 1 auto;

      &::-webkit-scrollbar-thumb {
        background-color: config.$white;
        @include config.border-radius(100px);
      }

      &::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
      }
    }

    .setting-user {
      @include config.flexbox();
      @include config.align-items(center);
      @include config.justify-content(space-between);
      margin-bottom: 50px;
      padding-bottom: 28px;

      .user-names {
        span {
          font-weight: normal;
          font-size: 16px;
          color: #ffffff;
          @include config.flexbox();
          @include config.align-items(center);
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            margin-right: 10px;
            flex: none;
          }
        }
      }

      .share-btn {
        background: linear-gradient(#131623, #131623) padding-box,
          linear-gradient(to right, #fa6401, #fa6401) border-box;
        border: 2px solid transparent;
        width: 44px;
        height: 44px;
        @include config.border-radius(6px);
        @include config.flexbox();
        @include config.align-items(center);
        @include config.justify-content(center);
        @include config.transition(0.3s, ease-in-out);
        cursor: pointer;

        img {
          width: 24px;
        }

        &:hover {
          background: linear-gradient(277.72deg,
              #c9009a -32.97%,
              #fc6401 55.57%,
              #f4f4f4 144.11%) padding-box,
            linear-gradient(277.72deg,
              #c9009a -32.97%,
              #fc6401 55.57%,
              #f4f4f4 144.11%) border-box;
        }
      }
    }

    .top-bar-block {
      background: linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
      padding: 22px;
      border-radius: 9px 9px 0 0;
    }

    .price-inner-block {
      margin-top: 50px;

      h3 {
        font-weight: 900;
        font-size: 22px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: config.$font-Saira;

        span {
          background: rgb(234, 156, 104);
          background: -moz-linear-gradient(90deg,
              rgba(234, 156, 104, 1) 0%,
              rgba(233, 84, 67, 1) 100%);
          background: -webkit-linear-gradient(90deg,
              rgba(234, 156, 104, 1) 0%,
              rgba(233, 84, 67, 1) 100%);
          background: linear-gradient(90deg,
              rgba(234, 156, 104, 1) 0%,
              rgba(233, 84, 67, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }

      .dec-text {
        p {
          margin-bottom: 0;
        }
      }

      .history-block {
        margin-top: 50px;
        max-width: 485px;
        border-radius: 10px;
        border: 1px solid #eb3636;
        padding: 20px;
        overflow: hidden;

        .history-scroll-block {
          overflow-y: auto;
          margin-bottom: 20px;
          max-height: 150px;
          padding-right: 10px;

          &::-webkit-scrollbar-thumb {
            background-color: config.$white;
            @include config.border-radius(100px);
          }

          &::-webkit-scrollbar {
            width: 3px;
            background-color: #000000;
          }
        }

        .dec-text {
          ~.dec-text {
            margin-top: 20px;
          }

          p {
            font-size: 14px;
            @include config.flexbox();
            @include config.align-items(center);
            @include config.justify-content(space-between);

            span {
              ~span {
                padding-left: 5px;
              }
            }
          }
        }

        .price-text {
          @include config.flexbox();
          @include config.align-items(center);
          @include config.justify-content(space-between);
          background: linear-gradient(90deg, #c9009a 0.01%, #fc6401 100.01%);
          padding: 15px;
          margin: 0 -20px -20px;

          h3 {
            margin-bottom: 0;
          }

          span {
            color: #f4f4f4;
            font-size: 14px;
            margin-left: 25px;
          }
        }
      }

      @media (max-width: 767px) {
        .history-block {
          margin-top: 30px;
          padding: 15px;

          .price-text {
            margin: 0 -15px -15px;

            span {
              margin-left: 10px;
            }
          }

          h4 {
            font-size: 18px;
          }

          .price-text,
          .dec-text {
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .banner-slider {
    .slick-arrow {
      filter: invert(1);
    }

    .slick-dots {
      li {
        button {
          background: #131623;
        }
      }
    }

    @media (min-width: 768px) {
      .slick-prev {
        bottom: 20px;
        right: 55px;
      }

      .slick-next {
        bottom: 20px;
        right: 20px;
      }

      .slick-dots {
        bottom: 30px;
        right: 100px;
      }
    }
  }

  .category-items {
    .card {
      .card-body {
        .title-sub-title {
          .card-title {
            font-size: 14px;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .price-buynow-btn {
    position: absolute;
    z-index: 10;
    bottom: -5px;
    left: -5px;
    box-sizing: border-box;
    color: #fff;
    background: #000000cc;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 0px 0px 15px 15px;
    width: calc(100% + 10px);

    @include config.flexbox();
    @include config.align-items(center);
    @include config.justify-content(space-between);

    .btn-primary,
    .btn-secondery {
      padding: 0 44px;
      min-width: auto;
      min-height: 44px;
    }

    .current-price {
      @include config.flexbox();
      @include config.align-items(center);

      h3 {
        font-weight: 900;
        font-size: 36px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: config.$font-Saira;
        margin: 0 0 0 15px;
      }
    }
  }

  .price-buynow-btn:after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(314.45deg,
        #c90099 -70.21%,
        #f67622 58.16%,
        #d8d8d8 143.73%);
  }

  .nftPriceLabel {
    font-size: 22px;
    font-family: "Archivo";
    padding: 20px 20px;

    @media (max-width: 540px) {
      font-size: 18px;
    }
  }

  .priceBreakLine {
    border-right: 1px solid orange;
    height: 30px;
    display: block;
  }

  .currentPriceLabel {
    font-size: 12px !important;
    font-family: "Archivo" !important;
    font-weight: normal !important;
    font-variation-settings: "wdth" 125;

    @media (max-width: 540px) {
      font-size: 10px !important;
    }
  }

  .nftBuyNow {
    background: #f57a29 !important;
    font-family: "Archivo" !important;
    font-weight: normal !important;
    font-variation-settings: "wdth" 125;
    margin-right: 10px;
    padding: 15px 44px !important;

    &:hover {
      color: black !important;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      padding: 5px 15px !important;
      margin-bottom: 10px;
    }
  }

  .nftBuyNowDisabled {
    opacity: 0.5 !important;
    background: #f57a29 !important;
    font-family: "Archivo" !important;
    font-weight: normal !important;
    font-variation-settings: "wdth" 125;
    margin-right: 10px;
    padding: 15px 44px !important;


    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
      color: white !important;
      background: #f57a29 !important;

      border: 2px solid transparent;
      box-shadow: none !important;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      padding: 5px 15px !important;
      margin-bottom: 10px;
    }
  }

  .timer-block {
    text-align: right;

    span {
      max-width: 162px;
      padding: 5px 15px;
      display: inline-block;
      width: 100%;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
    }
  }

  @media (max-width: 1199px) {
    .banner-content-block {
      h2 {
        font-size: 20px;
      }

      .setting-user {
        margin-bottom: 30px;
        padding-bottom: 20px;
      }
    }
  }

  @media (max-width: 991px) {
    .banner-content-block {
      margin-top: 35px;
      height: auto;

      h2 {
        font-size: 18px;
      }

      .price-inner-block {
        margin-top: 40px;
      }

      .setting-user {
        .user-names {
          span {
            font-size: 14px;
          }
        }

        .share-btn {
          width: 40px;
          height: 40px;

          img {
            width: 20px;
          }
        }
      }
    }

    .price-buynow-btn {
      .current-price {
        h3 {
          font-size: 22px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .banner-content-block {
      .price-inner-block {
        h3 {
          font-size: 18px;
        }
      }

      .top-bar-block {
        padding: 15px;
      }

      .scroll-block {
        padding: 15px;
      }
    }

    .price-buynow-btn {

      .btn-primary,
      .btn-secondery {
        padding: 0 15px;
        min-height: 40px;
        max-width: 140px;
        width: 100%;
      }

      .current-price {}

      .right-block {
        @include config.justify-content(space-between);
        @include config.flexbox();
        @include config.align-items(center);
        margin-top: 15px;
      }
    }
  }
}

.custom-html-dropdown {
  position: relative;

  .dropdown-input {
    background-color: transparent;
    color: #727272;
    font-size: 16px;
    border: none;
    @include config.border-radius(0px);
    padding: 0;
    min-width: 100%;
    text-align: left;

    &::after {
      content: none;
    }

    &:focus {
      outline: none;
    }
  }

  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    margin-top: 5px !important;
    background: linear-gradient(#131623, #131623) padding-box,
      linear-gradient(to right, #fa6401, #fa6401) border-box;
    border: 1px solid transparent;
    @include config.box-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
    min-width: 150px;
    display: block !important;
    z-index: 4;
    visibility: hidden;
    @include config.opacity(0);
    transition: all 0.2s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    transform: translateY(2em);
    padding: 10px 0;
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
    top: 42px !important;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s, 0s, 0.3s;
      transform: translateY(0) !important;
    }

    li {
      .dropdown-item {
        font-size: 14px;
        border: none;
        background: transparent;
        width: 100%;
        text-align: left;
        padding: 5px 15px;
        @include config.transition(0.3s, ease-in-out);
        color: #ffffff;
        padding: 4px 15px;

        &:focus {
          outline: none;
        }

        &:hover,
        &:focus {
          color: #ffffff;
          background: rgba(0, 0, 0, 1);
        }
      }
    }

    .dropdown-item {
      cursor: pointer;
      padding: 5px 15px;
      font-size: 14px;

      &:hover,
      &:focus {
        color: #ffffff;
        background: rgba(0, 0, 0, 1);
      }
    }
  }
}

.gradient-heading {
  margin-top: 40px;
  font-weight: 900;
  font-size: 36px;
  text-transform: uppercase;
  font-family: config.$font-Saira;

  span {
    background: rgb(234, 156, 104);
    background: -moz-linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 100%);
    background: -webkit-linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 100%);
    background: linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }

  @media (max-width: 1199px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
  }

  &:hover {
    cursor: pointer;
  }
}

// Custom class
.placeholder-class::placeholder {
  color: #f67622;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn {
  border: 2px solid rgb(248, 160, 102);
  color: #f67622;
  background-color: black;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.disabled-btn {
  opacity: 0.7;
  color: white;
  background: gray padding-box,
    // linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%)
    border-box;
  border: 2px solid transparent;
  min-height: 44px;
  padding: 0 20px;
  font-size: 16px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 400;
  min-width: 130px;
  @include config.transition(0.3s, ease-in-out);
  @include config.border-radius(6px);
  @include config.inline-flex;
  @include config.align-items(center);
  @include config.justify-content(center);

  strong {
    font-weight: 900;
  }

  img {
    margin-right: 8px;
  }

  &:after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: white !important;
    background: gray padding-box,
      linear-gradient(277.72deg,
        #c9009a -32.97%,
        #fc6401 55.57%,
        #f4f4f4 144.11%) border-box !important;
    border: 2px solid transparent;
    box-shadow: none !important;
  }

  @media (max-width: 767px) {
    min-width: 110px;
    min-height: 40px;
  }
}

.upload-btn-wrapper input[type="file"] {
  font-size: 50px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #f67622;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
  color: #f67622 !important;
}

input[type="range"] {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #f67622 !important;
}

.share-btn-active {
  background: linear-gradient(277.72deg,
      #c9009a -32.97%,
      #fc6401 55.57%,
      #f4f4f4 144.11%) padding-box,
    linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%) border-box;
  border: 2px solid transparent;
  width: 40px;
  height: 40px;
  @include config.border-radius(6px);
  @include config.flexbox();
  @include config.align-items(center);
  @include config.justify-content(center);
  @include config.transition(0.3s, ease-in-out);
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid #f67622;
  -webkit-text-fill-color: #f67622;
  transition: background-color 5000s ease-in-out 0s;
}

// input[type="text"]:focus {
//   opacity: 1 !important;
// }
// textarea:focus {
//   opacity: 1 !important;
// }
.scroll-thumb {
  &::-webkit-scrollbar-thumb {
    background-color: #f67622;
    @include config.border-radius(100px);
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  max-height: 400px;
  overflow-y: scroll;
}

.profile-picture-container {
  position: relative;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.profile-picture-container:hover {
  opacity: 0.3;

  .middle {
    opacity: 1;
    z-index: 10;
  }
}

.profile-picture-container:hover .middle {
  opacity: 1;
}

.profile-dropdown {
  &:hover {
    >img {
      @include config.transform(rotate(-180deg));
    }

    .dropdown-menu-list {
      @include config.opacity(1);
      visibility: visible;
      @include config.transform(translateY(0));
      @include config.transition(0.3s, ease-in-out);
    }
  }

  .dropdown-menu-list {
    position: absolute;
    min-width: 170px;
    top: 100%;
    right: 0;
    @include config.opacity(0);
    display: block !important;
    background: config.$darkBlack;
    visibility: hidden;
    border: 1px solid #f57a29;
    @include config.transform(translateY(20px));
    margin: 0px;
    list-style: none;
    padding: 10px 0;

    li {
      padding: 10px 18px;
      margin: 0;

      a {
        font-size: 16px;

        &:after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .headAvatar {
    margin-left: 0px !important;
  }

  .noPaddingMobRight {
    padding-right: 0px !important;
  }

  .noPaddingMobLeft {
    padding-left: 0px !important;
  }

  .video-player {
    video {
      width: 100%;
    }
  }
}

.marketText p {
  font-family: "Archivo" !important;
  font-weight: 500 !important;
  font-variation-settings: "wdth" 125;
  text-transform: unset !important;
}

.marketIcon {
  width: 25px;
  height: auto;
  margin-right: 20px;
}

.marketItemContainer {
  border-bottom: 1px solid salmon;
  padding: 25px 25px 5px 25px;
}

.marketParaBottom {
  padding-bottom: 10px;
}

.propertySubtainers {}

.propertySubtainers {
  position: relative;
  bottom: 3px;
  box-sizing: border-box;
  color: #fff;
  background: #131623;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 15px;
  width: calc(100% + 5px);
  padding-top: 10px;
  padding-bottom: 10px;
  @include config.flexbox();
  @include config.align-items(center);
  @include config.justify-content(space-between);
}

.propertySubtainers:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  /* !importanté */
  border-radius: 10px;
  /* !importanté */
  background: linear-gradient(314.45deg,
      #c90099 -70.21%,
      #f67622 58.16%,
      #d8d8d8 143.73%);
}

.marketPropertyContent {
  font-family: "Archivo" !important;
  font-weight: 500 !important;
  font-variation-settings: "wdth" 125;
  text-transform: unset !important;
  font-size: 9px;
  background: #ea9c68;
  background: linear-gradient(314.45deg,
      #c90099 -70.21%,
      #f67622 58.16%,
      #d8d8d8 143.73%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
  font-weight: 300;
}

.marketPropertyContent span {
  font-size: 20px;
  color: #ffffff !important;
  -webkit-text-fill-color: white;
}

.video-playerContainer {
  background: #131623;
}

.videoContainer {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 800px;
}

.overflow-hidden-padding {
  width: calc(100% + 20px);
  padding: 10px;
}

.individual-video-playerContainer {
  position: absolute;
  bottom: 66px;
  left: 1px;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

video {
  width: 100%;
  height: unset;
}

.default-collection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.overflow-hidden-padding {
  width: calc(100% + 20px);
  padding: 10px;
}

// .individual-video-playerContainer {
//   position: absolute;
//   bottom: 66px;
//   left: 1px;

//   video {
//     width: 100%;
//     height: unset;
//   }
// }
.individual-video-playerContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.timer-block {
  display: none !important;
}

.arrow-inner {
  margin-top: 30px;
}

.menu-list-none {
  display: none;
}

.menu-list-show {
  .dropdown-menu-list.collapse {
    display: none !important;
  }

  .dropdown-menu-list.collapse.show {
    display: block !important;
    position: absolute;
    top: 55px;
    right: 0px;
    background: #000000aa;
    border: 1px solid white;
  }
}

.hideCollectionSection {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.viewCollectionSection {
  @media (max-width: 991px) {
    display: none;
  }
}

.smallHeaderStyle {
  font-size: 24px !important;
}

.individualNFTPage {
  outline: 1px solid coral;
  outline-offset: 0px;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  border-radius: 10px;

  .price-buynow-btn {
    background: #131623 !important;
    background-clip: padding-box !important;
  }
}

.featureBannerImgWrap {
  display: block;
  float: left;
  width: 100%;
  aspect-ratio: 1.7 / 1;
  //height: 410px;

  @media (max-width: 767px) {
    //height: 200px !important;
  }


  background-size: cover !important;
  background-position: center center !important;
  border-radius: 10px;
}

video.featureBannerImgWrap {
  object-fit: cover;
  object-position: center;
}

.featuredReleases {
  //height: 410px;
  aspect-ratio: 1.7 / 1;

  @media (max-width: 767px) {
    //height: 200px !important;
  }
}

.searchBarWrap {
  display: flex;
  gap: 16px;
  // align-items: center;
}

.searchSelect {
  width: 300px;

  // .MuiInputBase-root {
  //   svg {
  //     color: rgba(233, 84, 67, 1) !important;
  //     z-index: 9;
  //   }

  //   fieldset {
  //     border: 1px solid #ffffff;
  //     background: #ffffff;
  //     border-radius: 10px;
  //   }
  // }

  // .MuiFormLabel-colorPrimary {
  //   background: rgb(234, 156, 104);
  //   background: -moz-linear-gradient(90deg,
  //       rgba(234, 156, 104, 1) 0%,
  //       rgba(233, 84, 67, 1) 50%,
  //       rgba(201, 0, 154, 1) 100%);
  //   background: -webkit-linear-gradient(90deg,
  //       rgba(234, 156, 104, 1) 0%,
  //       rgba(233, 84, 67, 1) 50%,
  //       rgba(201, 0, 154, 1) 100%);
  //   background: linear-gradient(90deg,
  //       rgba(234, 156, 104, 1) 0%,
  //       rgba(233, 84, 67, 1) 50%,
  //       rgba(201, 0, 154, 1) 100%);
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   font-family: "Archivo";
  //   font-weight: 500;
  //   font-size: 16px;
  //   //font-variation-settings: "wdth" 125;
  // }
}

.viewToggle {
  border: 1px solid white;
  border-radius: 10px !important;

  button:nth-child(1) {
    border-right: 1px solid white;
    border-radius: 10px;
  }

  button {
    padding: 13px 15px;

    svg {
      fill: white;
      color: white;
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}

.gradient-box {
  align-items: center;
  position: relative;
  top: 0;
  box-sizing: border-box;
  $border: 1px;
  color: #fff;
  background: #131623;
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 10px;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: -moz-linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 50%,
        rgba(201, 0, 154, 1) 100%);
    background: -webkit-linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 50%,
        rgba(201, 0, 154, 1) 100%);
    background: linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 50%,
        rgba(201, 0, 154, 1) 100%);
  }
}

.filterCollapsesWrapper {
  .marketText {
    margin-bottom: 0px;
    background: rgb(234, 156, 104);
    background: -moz-linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 50%,
        rgba(201, 0, 154, 1) 100%);
    background: -webkit-linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 50%,
        rgba(201, 0, 154, 1) 100%);
    background: linear-gradient(90deg,
        rgba(234, 156, 104, 1) 0%,
        rgba(233, 84, 67, 1) 50%,
        rgba(201, 0, 154, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea9c68", endColorstr="#e95443", GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Archivo";
    font-weight: 500;
    font-size: 16px;
  }
}

.filterCollapses {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid coral;
  cursor: pointer;
}

.filterContent {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterRadioGroup {
  width: 100%;

  label {
    justify-content: space-between;
    margin: 0px !important;
  }
}

.filterItem {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 10px;
  position: relative;
}

.filterItemDetail {
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  width: 100%;

  p {
    margin: 0px;
    font-size: 14px;

    span {
      font-size: 12px;
    }

    .nftPrice {
      color: coral;
    }
  }
}

.filterHideButton {
  background: linear-gradient(277.72deg,
      #c9009a -32.97%,
      #fc6401 55.57%,
      #f4f4f4 144.11%) padding-box,
    linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%) border-box;
  min-height: 44px;
  padding: 28px 30px 10px 15px;
  width: 35px;
  border-radius: 10px 0px 0px 10px;
  height: 80px;
  position: absolute;
  left: -33px;
  top: 25px;
  z-index: 5;
}

.filterShowButton {
  background: linear-gradient(277.72deg,
      #c9009a -32.97%,
      #fc6401 55.57%,
      #f4f4f4 144.11%) padding-box,
    linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%) border-box;
  min-height: 44px;
  padding: 28px 30px 10px 15px;
  width: 35px;
  border-radius: 10px 0px 0px 10px;
  height: 80px;
  position: absolute;
  left: -33px;
  top: 25px;
  z-index: 5;
}
.filterButtonDiv{
  width:250px;  
  display: flex;
  justify-content: center;
  align-items: center; 
  border-radius: 10px;
  background: linear-gradient(277.72deg,
  #c9009a -32.97%,
  #fc6401 55.57%,
  #f4f4f4 144.11%) padding-box,
  linear-gradient(277.72deg, #c9009a -32.97%, #fc6401 55.57%, #f4f4f4 144.11%) border-box;
  padding: 0%; 
  cursor: pointer;
}

.link-div {
  :hover {
    cursor: pointer !important;
    color: "#f67622" !important;
  }
}



// license agreement css 
.bg-video {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: -1;

  .ratio {
    height: 100vh;
  }

}
.embed-video{
  height: 100vh !important;
  width: auto;
}

// home collect grid
.homeCollectionList .gridAreaCollectionWrap:nth-child(2n + 1) {
  display: grid;
  grid-template-areas: 'homeCollection1 homeCollection2';
  grid-template-columns: 1fr 2fr;

  @media (max-width: 1199px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    grid-template-areas: 'homeCollection2'
      'homeCollection1';
    grid-template-columns: 1fr;
  }

  .gridAreaCollection1 {
    grid-area: homeCollection1;
    width: 100%;
  }

  .gridAreaCollection2 {
    grid-area: homeCollection2;
    width: 100%;
  }
}

.homeCollectionList .gridAreaCollectionWrap:nth-child(2n + 2) {
  display: grid;
  grid-template-areas: 'homeCollection2 homeCollection1';
  grid-template-columns: 2fr 1fr;

  @media (max-width: 1199px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    grid-template-areas: 'homeCollection2'
      'homeCollection1';
    grid-template-columns: 1fr;
  }

  .gridAreaCollection1 {
    grid-area: homeCollection1;
    width: 100%;
  }

  .gridAreaCollection2 {
    grid-area: homeCollection2;
    width: 100%;
  }
}

.collectionHtml {
  >ul li::marker {
    content: '✓';
    color: #fc6300;
    font-weight: bold;
  }

  >ul {
    margin-left: 20px;
    font-size: 1.4rem;
    text-align: left;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.solis-orange-2 {
  color: #fc6300;
}

.collectionHtmlDiv {
  margin-bottom: 30px;
  font-family: sans-serif;
  // text-align: center;

  >p {
    margin: 0px;
  }

  >.pHeading {
    font-size: 2rem;
  }

  >.pText {
    font-size: 1.5rem;
  }

  >ul {
    margin: 0px;
    font-size: 1.5rem;
  }

  >.margin {
    margin-top: 15px;
  }

  >.left {
    text-align: left;
    font-size: 1.6rem;
  }
}

.leftAlign {
  text-align: left;
}

#transak_modal {
  z-index: 15000; 
}
.settlementText{
  display: none;
}
.preAssignedImage:hover > .settlementText{
  display: block;
}
.headerUserImageDiv{
  position: relative;
}
.headerCircularProgress{
  margin: 0px;
  padding: 0px;
}

//buy with fiat/crypto styles 
.steps-container-div{
  @media (min-width: 1200px) {
    margin-bottom: 200px;
  }
  @media(max-width:1199px){
    margin-bottom: 200px;
  }
  @media (max-width: 990px) {
    margin-bottom: 150px;
  }
  @media (max-width: 767px) {
    margin-bottom: 150px;
  }
  @media (max-width: 500px) {
    margin-bottom: 100px;
  }
}
.steps-container-div-relative{
  position: relative;
  @media (min-width: 1200px) {
    margin-bottom: 200px;
  }
  @media(max-width:1199px){
    margin-bottom: 200px;
  }
  @media (max-width: 990px) {
    margin-bottom: 150px;
  }
  @media (max-width: 767px) {
    margin-bottom: 150px;
  }
  @media (max-width: 500px) {
    margin-bottom: 100px;
  }
}
.buy-options-heading-white{
  color:white;
  font-family: config.$font-Saira ;
  @media (min-width: 1200px) {
    font-size: 48px;
  }  
  @media (max-width: 1199px) {
    font-size: 48px;
  }
  @media (max-width: 990px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
}
.buy-options-heading-white-without-margin{
  color:white;
  font-family: config.$font-Saira ;
  @media (min-width: 1200px) {
    font-size: 48px;
  }  
  @media (max-width: 1199px) {
    font-size: 48px;
  }
  @media (max-width: 990px) {
    font-size: 36px; 
    text-align: center;
    margin: 0px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
    text-align: center;
    margin: 0px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
    text-align: center;
    margin: 0px;
  }
}
.steps-text-orange{
  margin-top: 20px;
  @media (min-width: 1200px) {

  }  
  @media (max-width: 1199px) {
    font-size: 48px;
  }
  @media (max-width: 990px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
}
.steps-orange{
  color: #f67622;
  font-family: config.$font-Archivo;
  @media (min-width: 1200px) {
    font-size: 24px;
  }  
  @media (max-width: 1199px) {
    font-size: 20px;
  }
  @media (max-width: 990px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size:16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
}
.steps-orange-italic{
  color: #f67622;
  font-family: config.$font-Archivo; 
  font-style: italic;
  @media (min-width: 1200px) {
    font-size: 24px;
  }  
  @media (max-width: 1199px) {
    font-size: 20px;
  }
  @media (max-width: 990px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size:16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
}
.steps-orange-without-margin{
  color: #f67622;
  font-family: config.$font-Archivo;
  margin: 0;
  padding: 0;
  @media (min-width: 1200px) {
    font-size: 24px;
  }  
  @media (max-width: 1199px) {
    font-size: 20px;
  }
  @media (max-width: 990px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size:16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.orange-strong{
  color: #FC6300;
  font-weight: 700 !important;
}
.steps-images{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 1200px) {
    max-height: 800px;
    max-width: 800px; 
  }  
  @media (max-width: 1199px) {
    max-height: 700px;
    max-width: 700px; 
  }
  @media (max-width: 990px) {
    max-height: 600px;
    max-width: 600px; 
  }
  @media (max-width: 767px) {
    max-height: 450px;
    max-width: 450px; 
  }
  @media (max-width: 500px) {
    max-height: 350px;
    max-width: 350px; 
  }
}
.text-image-left-container{
  flex-grow: wrap;
  @media (min-width: 1200px) {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
  }  
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
}

.text-flex-column-container{
  display: flex;
  flex-direction: column;
  gap: 100px !important;
  margin-bottom: 20px;
}
.text-image-right-container{
    @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    >.align-bottom-container{
      display: flex;
      flex-direction: column; 
      align-content: flex-end;
    }
   }  
   @media (max-width: 1199px) {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
   }
   @media (max-width: 990px) {
     display: flex;
     flex-direction: column;
   }
   @media (max-width: 767px) {
     display: flex;
     flex-direction: column;
   }
   @media (max-width: 500px) {
     display: flex;
     flex-direction: column;
   }
}

.center-text-div-kyc{
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  transform: translateY(25%);
  gap: 50px;
}

.home-kyc-flex-container{
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

  }
  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}
.buy-guide-homepage{
  min-height: 70vh;
  position: relative; 

}
.buy-guide-homepage-relative{
  position: relative;
  min-height: 70vh;
  @media(max-width:786px) {
    min-height: 50vh;
  }
  @media(max-width:500px) {
    min-height: 35vh;
  }
  position: relative;
}
.footer-kyc-logo{
  width: 150px;
  @media (max-width: 767px) {
      width: 60px;
      
  }
}
.kyc-footer-div{
  position: absolute;
  bottom: 100px; 
  display: flex;
  justify-content: space-between;
  width: 100%; 
  font-weight: 700; 
  @media (max-width: 990px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
  }
}
.image-with-related-text-container{
  @media(min-width:786px) {
    display: flex;
    justify-content: center;
  }
}

.left-align-kyc-image{
  @media(min-width: 786px) {
    display: initial;
  }
}

.flex-row-right-column-kyc-container{
  display: flex;

  align-items: center;
  @media(min-width:786px){
    margin-bottom: 150px;
    gap: 50px;
    align-self: flex-end;
  }
}

.kyc-page-powered-by-footer-text-div{
  position: absolute;
  bottom: 0;
  right: 0;
}

.powered-by-image{
  @media (min-width: 1200px) {
    width: 100% !important;
  }  
  @media (max-width: 1199px) {
    width: 80%
  }
  @media (max-width: 990px) {
    width:100px
  }
  @media (max-width: 767px) {
    width:80px
  }
  @media (max-width: 500px) {
    width: 50px;
  }
}
.powered-by-text{
  @media (min-width: 1200px) {
    font-size: 14px;
    text-align: right;
  }  
  @media (max-width: 1199px) {
    font-size: 12px;
    text-align: right;
  }
  @media (max-width: 990px) {
    font-size: 10px;
    text-align: right;
  }
  @media (max-width: 767px) {
    font-size: 8px;
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 5px;
    text-align: center;
  }
}
.fake-div{
  height:100px
}
.filter-container{
  margin-top: 2vh !important;
  // overflow-y: auto;
}
.collection-with-filter{
  // display: flex;
  // flex-direction: row;
  // @media(max-width: 999px) {
  //   flex-direction: column;
  // } 
  // gap: 20px;  
  display: grid; 
  grid-template-columns: 25% 73%; 
  grid-column-gap: 2%; 
  @media(max-width: 999px) {
    display: flex;
    flex-direction: column;
    gap: 20px
  } 
}

.sticky-parent{
  position: sticky;
  width: 100%;  
  @media(max-width:999px) {
    width: 100%;
  }
}
.scrollable{
  overflow-y: auto !important;
}

.no-collectibles-info-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0px 0px 20px 0px #f67622;
  border: 1px solid #f67622;
  background-color: rgb(20, 22, 35);
  min-width: 200px;
  max-width: 90%; 
  width: fit-content;
  min-height: 100px;
  max-height: 90%; 
  height: fit-content;
  overflow: auto;
}
.left-text-with-image{
  flex-grow: wrap;
  @media (min-width: 1200px) {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
  }  
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }
  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
}

.gradientText {
  background: linear-gradient(90deg, rgb(201, 0, 154) 0.01%, rgb(252, 100, 1) 100.01%);
  // background: #f67622;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
}
.gradientTextParent{
  background-color: black;
  box-shadow: 0px 0px 20px 0px #f67622;
  padding: 10px;
  margin: auto;
  width: 80%;
  border-radius: 10px;
  border: 1px solid #f67622;
  margin-top: 10px;
}

@media (max-width: 1400px) {
  .gradientText {
    font-size: 1rem;
  }
  .gradientParent{
    margin-top: 10px;
  }
}


@media (max-width: 1200px) {
  .gradientText {
    font-size: 0.9rem;
  }
  .gradientParent{
    margin-top: 20px;
    width: 85%;
  }
}

@media (max-width: 600px) {
  .gradientText {
    font-size: 0.9rem;
  }
  .gradientParent{
    margin-top: 20px;
    width: 90%;
  }
}

@media (max-width: 400px) {
  .gradientText {
    font-size: 0.8rem;
  }
  .gradientParent{
    margin-top: 2px;
    width: 90%;
  }
}



.searchContainer {
  position: relative;
  width: 100%;
}



.searchSuggestions {
  position: absolute;
  z-index: 100;
  width: 100%;
  color:#f67622;
  background: rgb(20,22,35);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
  padding: 10px 0; 
}

.searchList {
  border: 1px solid white;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 20px; 
  color: white;

  transition: background 0.1s ease; 
}

.searchList:hover {
  background: black;
  color:#f67622; 
  border:1px solid #f67622; 
}

