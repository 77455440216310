@import url('//fonts.googleapis.com/css2?family=Archivo:wdth,wght@100,300;100,400;100,500;100,700;100,800;100,900;125,400&family=Saira:wdth,wght@100,300;100,400;100,500;100,700;100,800;100,900;125,900&family=Abril+Fatface&family=Actor&family=Aldrich&display=swap');
/* Variables */
$bodyfont: 'Actor', sans-serif;
$bodyfontsize: 16px;
$bodyfontcolor: #f4f4f4;
$bodybg: #131623;
$darkBlack: #040404;
$white: #ffffff;
$black: #000000;

$font-Saira:'Saira', sans-serif;
$font-Abril-Fatface:'Abril Fatface', sans-serif;
$font-Actor:'Actor', sans-serif;
$font-Aldrich:'Aldrich', sans-serif;
$font-Archivo:'Archivo', sans-serif;

/* Mixins */
@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}
@mixin box-shadow($box-shadow) {
  -moz-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}
@mixin box-shadow-all($bs-t, $bs-r, $bs-b, $bs-l, $box-shadow-all-color) {
  box-shadow: $bs-t $bs-r $bs-b $bs-l $box-shadow-all-color;
  -webkit-box-shadow: $bs-t $bs-r $bs-b $bs-l $box-shadow-all-color;
  -moz-box-shadow: $bs-t $bs-r $bs-b $bs-l $box-shadow-all-color;
  -o-box-shadow: $bs-t $bs-r $bs-b $bs-l $box-shadow-all-color;
}

@mixin transition($duration, $ease) {
  -webkit-transition: all $duration $ease;
  -moz-transition: all $duration $ease;
  -o-transition: all $duration $ease;
  transition: all $duration $ease;
}
@mixin transitionValue($value, $duration, $ease) {
  -webkit-transition: $value $duration $ease;
  -moz-transition: $value $duration $ease;
  -o-transition: $value $duration $ease;
  transition: $value $duration $ease;
}
@mixin opacity($value) {
  -moz-opacity: $value;
  -khtml-opacity: $value;
  -webkit-opacity: $value;
  opacity: $value;
}
@mixin transform($value) {
  -moz-transform: $value;
  -webkit-transform: $value;
  -o-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin filter($value) {
  -webkit-filter: $value;
  -moz-filter: $value;
  -o-filter: $value;
  filter: $value;
}

/* display: flex */
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}
// Display Order
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

/** Operation **/
/* Parametric Mixins */
/** Guarded Mixins **/
